$( document ).ready(function() {	
	BASE.onReady();
	console.log( $(window).width() );
});

// Controller for mobile menu: show & hide
jQuery(document).on('click','header.site .toggle', function(e) {
	e.preventDefault();
	jQuery('.main_navigation_container').toggleClass('active');
	jQuery(this).toggleClass('active');
});		

// Controller for mobile menu: expand the submenus and following links
jQuery(document).on('click','.main_navigation_container > .main_navigation_container_inner > nav > ul > li > a', function(e) {
	e.preventDefault();

	// when element has children (sub menu items), expand submenus
	if ( $(this).parent().has('ul').length > 0 && $(window).width() < 1180 ) {
		$(this).parent().parent().find('ul').slideUp();
		$(this).parent().find('ul').slideDown();

	// when element has no children (sub menu items), follow link
	} else {
		window.location.href = $(this).attr('href');
	}

});		


BASE = {
	onReady: function(){
		BASE.wrapButtons(); // since TinyMCE settings don't allow a combination of link class and wrapper container, the button outline is set here.
	},

	wrapButtons: function(){
		
		$('.btn').each(function(i,v) {
			if ( $(v).parent().attr('class') != 'btn_wrapper' ) {
				$(v).wrap('<span class="btn_wrapper"></span>');

			}
		});


	}

}

